import { FormElementErrorMessage, FormElementLabel } from './Input'
import { CustomMessageType } from '../FormBuilder'
import React, { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

export const ZipCodeInput = ({
  label = '',
  property = undefined,
  required = false,
  showOptionalIfNotRequired = true,
  readonly = false,
  register = (...args) => null,
  setValue = (...args) => null,
  getValues = (...args) => null,
  validators = {} as any,
  minLength = undefined,
  maxLength = undefined,
  errors = undefined,
  disabled = false,
  customMessage = undefined,
  customMessageType = CustomMessageType.Error,
  additionalActionText = '',
  additionalActionFn = null,
  helpText = null,
}) => {
  const { t } = useTranslation()
  const [zipCodeValue, setZipCodeValue] = useState('')
  const [currentZipCodeRaw, setCurrentZipCodeRaw] = useState('')
  const [zipCodeValidationState, setZipCodeValidationState] = useState('undefined')

  useEffect(() => {
    const formValue = getValues != null ? getValues(property) : null
    if (formValue != null && formValue != '') {
      setCurrentZipCodeRaw(formValue)
      setZipCodeValue(applyZipCodeMask(formValue))
    }
  }, [])

  const applyZipCodeMask = (value: any) => {
    value = value
      .replace(/\D/g, '')
      .replace(/^(\d{5})(\d)/, '$1-$2')
      .slice(0, 9)

    return value
  }

  const removeZipCodeMask = (value: string) => {
    value = value.replace(/\D/g, '')
    return value.substring(0, 8)
  }

  const requestAddressData = async (zipCode: string) => {
    if (zipCode.length !== 8) {
      setZipCodeValidationState('checking')
      return
    }

    setZipCodeValidationState('checking')

    try {
      const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
      if (!response.ok) {
        console.error('Error fetching the ZIP code')
        setZipCodeValidationState('undefined')
        return
      }

      const addressData = await response.json()
      if (addressData.erro) {
        setZipCodeValidationState('invalid')
        return
      }

      setZipCodeValidationState('valid')

      const fields = {
        streetAddress: addressData.logradouro,
        locality: addressData.bairro,
        city: addressData.localidade,
        region: addressData.uf,
      }

      Object.entries(fields).forEach(([key, value]) => {
        if (getValues(key) === undefined) return
        setValue(key, value || '', { shouldValidate: true })
      })
    } catch (error) {
      console.error('Error fetching the ZIP code', error)
      setZipCodeValidationState('undefined')
      return
    }
  }

  const onZipCodeChange = (ev: any) => {
    const { value } = ev.target

    const formattedValue = applyZipCodeMask(value)
    const rawZipCode = removeZipCodeMask(value)

    setZipCodeValue(formattedValue)
    setCurrentZipCodeRaw(rawZipCode)

    setValue(property, rawZipCode, {
      shouldValidate: true,
      shouldTouch: true,
    })

    if (rawZipCode.length < 8 && zipCodeValidationState === 'valid') {
      clearAddressFields()
      setZipCodeValidationState('undefined')
      return
    }

    if (rawZipCode.length === 8) {
      requestAddressData(rawZipCode)
    }
  }

  const clearAddressFields = () => {
    setValue('streetAddress', '')
    setValue('locality', '')
    setValue('city', '')
    setValue('region', '')
  }

  return (
    <div className="zipCode-input flex flex-col mb-4">
      <FormElementLabel
        label={label}
        property={property}
        required={required}
        readonly={readonly}
        showOptionalIfNotRequired={showOptionalIfNotRequired}
        helpText={helpText}
      />

      <div
        className={`zipCode-input-inner relative flex space-x-2 items-center transition 
          bg-form hover:bg-formHover text-formContrast hover:text-formHoverContrast
          border-[.5px] border-formBorder hover:border-formHoverBorder rounded-md h-[42px] px-4 py-[10px] ${
            !disabled && !readonly ? 'focus:border-primary' : ''
          } ${errors != null ? 'border-red-500 border-opacity-100' : ''} ${readonly || disabled ? ' opacity-60' : ''}`}
      >
        <input
          className={`zipCode-input-input flex w-full h-full break-all text-sm bg-transparent focus:outline-none appearance-none`}
          placeholder="00000-000"
          onChange={onZipCodeChange}
          readOnly={readonly}
          disabled={disabled}
          value={zipCodeValue}
          type="text"
          inputMode="text"
        />

        {zipCodeValidationState != 'undefined' && (
          <div className={'absolute right-0 h-full aspect-square flex items-center justify-center'}>
            {zipCodeValidationState === 'valid' && (
              <FontAwesomeIcon icon={faCheckCircle} className={'text-green-500'} />
            )}
            {zipCodeValidationState === 'invalid' && (
              <FontAwesomeIcon icon={faExclamationCircle} className={'text-red-500'} />
            )}
            {zipCodeValidationState === 'checking' && (
              <FontAwesomeIcon icon={faSpinnerThird} className={'text-blue-500 animate-spin'} />
            )}
          </div>
        )}

        <input
          type="hidden"
          id={property}
          name={property}
          value={currentZipCodeRaw}
          {...register(property, {
            required: required,
            validate: validators,
          })}
        />
      </div>

      {additionalActionText && (
        <div
          className="zipCode-input-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer"
          onClick={additionalActionFn}
        >
          {additionalActionText}
        </div>
      )}

      <FormElementErrorMessage
        customMessage={customMessage}
        customMessageType={customMessageType}
        errors={errors}
        minLength={minLength}
        maxLength={maxLength}
      />
    </div>
  )
}
