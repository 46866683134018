import { FormElementErrorMessage, FormElementLabel } from './Input'
import { CustomMessageType } from '../FormBuilder'
import React, { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { isNullOrEmpty } from '../../../util/string'

export const StreetAddressInput = ({
  label = '',
  property = undefined,
  required = false,
  showOptionalIfNotRequired = true,
  readonly = false,
  register = (...args) => null,
  setValue = (...args) => null,
  getValues = (...args) => null,
  onChange = (...args) => null,
  validators = {} as any,
  minLength = undefined,
  maxLength = undefined,
  errors = undefined,
  disabled = false,
  value = '',
  customMessage = undefined,
  customMessageType = CustomMessageType.Error,
  additionalActionText = '',
  additionalActionFn = null,
  helpText = null,
}) => {
  const { t } = useTranslation('account')

  const [streetName, setStreetName] = useState(getValues(property) || '')
  const [additionalAddressInfo, setAdditionalAddressInfo] = useState('')

  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    parseStreetAddress(getValues(property))
  }, [getValues(property)])

  const parseStreetAddress = (value: string) => {
    if (value == null) return

    if (value == '') {
      setStreetName('')
      setAdditionalAddressInfo('')
      updateTotalValue('', '', false)
      return
    }

    if (value.indexOf('|') === -1) {
      setStreetName(value)
      setAdditionalAddressInfo('')
      updateTotalValue(value, '')
    } else {
      const splitted = value?.split('|')
      const _streetName = splitted[0]
      const _additional = splitted[1]

      setStreetName(_streetName)
      setAdditionalAddressInfo(_additional)
      updateTotalValue(_streetName, _additional)
    }
  }

  const onStreetAddressChange = (ev) => {
    const { value } = ev.target
    setStreetName(value)
    updateTotalValue(value, additionalAddressInfo)
  }

  const onAdditionalAddressChange = (ev) => {
    const { value } = ev.target
    setAdditionalAddressInfo(value)
    updateTotalValue(streetName, value)
  }

  const updateTotalValue = (_streetName: string, _additionalAddressInfo: string, revalidate: boolean = true) => {
    let value = _streetName
    if (_additionalAddressInfo) value += '|' + _additionalAddressInfo
    setCurrentValue(value)
    onChange(value)

    // set value manually, otherwise not recognized by react-hook-forms
    setValue(property, value, {
      shouldValidate: revalidate,
      shouldTouch: true,
    })
  }

  return (
    <>
      <div className="input flex flex-col mb-4">
        <FormElementLabel
          label={label}
          property={property}
          required={required}
          readonly={true}
          showOptionalIfNotRequired={showOptionalIfNotRequired}
          helpText={helpText}
        />

        <div
          className={`street-input-inner relative flex space-x-2 items-center transition 
          bg-form hover:bg-formHover text-formContrast hover:text-formHoverContrast
          border-[.5px] border-formBorder hover:border-formHoverBorder rounded-md h-[42px] px-4 py-[10px] ${
            !disabled && !readonly ? 'focus:border-primary' : ''
          } ${errors != null ? 'border-red-500 border-opacity-100' : ''} ${readonly || disabled ? ' opacity-60' : ''}`}
        >
          <input
            className={`street-input-input flex w-full h-full break-all text-sm bg-transparent focus:outline-none appearance-none`}
            placeholder={t('Example.streetAddress')}
            onChange={onStreetAddressChange}
            readOnly={readonly}
            disabled={disabled}
            value={streetName}
            type="text"
            inputMode="text"
          />
        </div>
      </div>

      <div className="input flex flex-col mb-4">
        <FormElementLabel
          label={t('Account.additionalAddressInfo')}
          property={property}
          required={false}
          readonly={readonly}
          showOptionalIfNotRequired={showOptionalIfNotRequired}
          helpText={helpText}
        />

        <div
          className={`additionalInfo-input-inner relative flex space-x-2 items-center transition 
          bg-form hover:bg-formHover text-formContrast hover:text-formHoverContrast
          border-[.5px] border-formBorder hover:border-formHoverBorder rounded-md h-[42px] px-4 py-[10px] ${
            !disabled && !readonly ? 'focus:border-primary' : ''
          } ${errors != null ? 'border-red-500 border-opacity-100' : ''} ${readonly && disabled ? ' opacity-60' : ''}`}
        >
          <input
            className={`additionalInfo-input-input flex w-full h-full break-all text-sm bg-transparent focus:outline-none appearance-none`}
            placeholder={t('Example.additionalAddressInfo')}
            onChange={onAdditionalAddressChange}
            readOnly={false}
            disabled={disabled}
            value={additionalAddressInfo}
            type="text"
            inputMode="text"
          />
        </div>
      </div>
      <input
        type="hidden"
        id={property}
        name={property}
        value={currentValue}
        {...register(property, {
          required: required,
          validate: validators,
        })}
      />

      {additionalActionText && (
        <div
          className="street-address-input-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer"
          onClick={additionalActionFn}
        >
          {additionalActionText}
        </div>
      )}

      <FormElementErrorMessage
        customMessage={customMessage}
        customMessageType={customMessageType}
        errors={errors}
        minLength={minLength}
        maxLength={maxLength}
      />
    </>
  )
}
